import React from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import Content_FeatureTrust from "components/content/feature_trust-backups.js";
import Content_FeatureControl from "components/content/feature_control-backups.js";
import Content_StorageOptimized from "components/content/feature_storage-optimized.js";
import Content_StorageMigration from "components/content/feature_storage-migration.js";

const Page = () => (
  <Layout.Layout textColor="dark">
    <Layout.SEO
      title="Automated GitLab Backup"
      description="Automate your GitLab backups. Connect your GitLab account, schedule when and where to store your backups."
    />
    <Layout.Hero
      type="primary"
      title={(<>Automated <span className="text-primary">GitLab</span> backup.</>)}
      subTitle={(
        <>Backup and restore your GitLab repo, accounts, issues, wiki and more.<br/>
          Trust your GitLab data is always available and stored where you want.
        </>)}
      checkMarks={['Repositories', 'Issues', 'Merge Requests', 'Snippets']}
      icons={[
        <p className="mb-2 font-light text-xs tracking-wider uppercase opacity-75">Fully integrated with:</p>,
        <Ui.Image filename={`brands/icons/gitlab-ico.png`} alt={`GitLab backup`}  className="w-6"/>,
      ]}
      cta={(<Ui.SignupButton className="mt-6" noSubtitle text="Automate your GitLab Backup" urlAtrr={{ sb_source: "website", sb_term: `gitlab-hero` }} />)}
      illustration={(
        <Ui.Image className="" filename="simplebackups-hero-dashboard.png" alt="SimpleBackups GitLab backup dashboard: scheduled GitLab Backups"/>
      )}
    />

    <Ui.Container>
      <Sections.JoinBrandsSection />
    </Ui.Container>

    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <Ui.FeatureRows items={
            [
              {
                preTitle: "trust your data is secure",
                title: (<>Never lose your <span className="text-primary">GitLab</span> data.</>),
                description: (
                  <>
                    <p>
                      Connect your GitLab account and enable automated backup of your <span
                      className="link-highlight">repositories</span> together with <span className="link-highlight">merge requests</span>, <span
                      className="link-highlight">projects</span>, <span className="link-highlight">issues</span>, etc.
                      <br/><br/>Store the backups where you want, bring your own storage and don't worry about maintaining
                      any GitLab API or script.
                    </p>
                  </>
                ),
                image: (<Ui.Image
                  className="shadow-2xl rounded-xl"
                  filename='simplebackups-app-saas-app-backup-gitlab.png'
                  alt="GitLab backup service"
                  style={{maxWidth: 450}}
                />)
              },
              Content_FeatureControl({subject: 'GitLab'}),
              Content_FeatureTrust(),
            ]}></Ui.FeatureRows>

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Ui.Section color="alt">
      <Ui.Container>
        <Ui.Row>
          <Ui.Typography tag="h2" className="text-center">GitLab Backup as a service</Ui.Typography>

          <div className="grid md:grid-cols-4 md:gap-4 gap-4 text-left mt-16">
            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-binary-lock text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Encrypted GitLab Backups</h3>
              <p className="text-base">
                <span className="link-highlight">All you GitLab backups are encrypted</span> safeguarding it against unauthorized access..</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-flask-gear text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Backup all GitLab</h3>
              <p className="text-base"><span className="link-highlight">Select what to back up and what to exclude</span>. Get full control of what each of your backup should contain.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-bell-concierge text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">Managed GitLab Backups</h3>
              <p className="text-base">No-code, no infrastructure required. Specifically designed to <span className="link-highlight">backup GitLab without the hassle</span>.</p>
            </div>

            <div className="bg-white rounded-xl p-8">
              <i className="fad fa-arrow-rotate-left text-primary text-4xl mb-4" />
              <h3 className="text-lg font-bold mb-1 h-8 block">One-click Restoration</h3>
              <p className="text-base">Restore your <span className="link-highlight">point-in-time GitLab data</span> using a simple method. You're covered.</p>
            </div>
          </div>

          <div class="mt-20 mb-8"><Ui.Typography tag="h3" className="text-2xl text-center">Wait... there's even more...</Ui.Typography></div>
          <Sections.FeatureSlider tags={["knack"]} />

        </Ui.Row>
      </Ui.Container>
    </Ui.Section>
    
    <Sections.SectionLearn
      title={<>Wanna learn more about GitLab backup?</>}
      text={``}
      tags={['saas-backup', 'saas-gitlab']}
    />

    <Sections.SectionGetStarted/>
    <Sections.SectionTestimonialExtended />
    <Sections.SectionFaq color="alt" faqs={['what_gitlab_backup_service', 'gitlab_is_there_a_data_limit']}/>

  </Layout.Layout>
)

export default Page
